<template>
    <b-overlay :show="is_busy" rounded="sm">
        <b-container fluid>
            <b-row class="mb-2">
                <b-col md="6">
                    <h2><strong>Academic Settings</strong></h2>
                </b-col>
            </b-row>

            <div class="card">
                <b-form @submit.prevent="updateUser()">
                    <div class="card-body">
                        <div class="row">
                            
                            <div class="col-md-3">
                                <b-form-group label="Current Term:">
                                    <b-form-select
                                        id="input-3"
                                        v-model="form.current_term"
                                        :options="terms"
                                        required
                                    ></b-form-select>
                                </b-form-group>
                            </div>

                            <div class="col-md-3">
                                <b-form-group label="Cut Off Mark (%):">
                                <b-form-input v-model="form.cut_off" required></b-form-input>
                                </b-form-group>
                            </div>

                            <div class="col-md-3">
                                <b-form-group label="No of Assessments:">
                                <b-form-select
                                    v-model="form.assessment"
                                    :options="assessments"
                                    required
                                ></b-form-select>
                                </b-form-group>
                            </div>

                            <div class="col-md-3">
                                <b-card-text class="mb-0">Enable Automatic Promotion?</b-card-text>
                                <b-form-checkbox :checked="form.promotion" v-model="form.promotion" class="custom-control-danger" name="check-button" switch />
                            </div>

                            <div class="col-md-2">
                                <b-form-group label="Assessment 1:">
                                <b-form-input v-model="form.test1" required></b-form-input>
                                </b-form-group>
                            </div>
                            
                            <div class="col-md-2" v-if="form.assessment>1">
                                <b-form-group label="Assessment 2:">
                                <b-form-input v-model="form.test2" required></b-form-input>
                                </b-form-group>
                            </div>

                            <div class="col-md-2" v-if="form.assessment>2">
                                <b-form-group label="Assessment 3:">
                                <b-form-input v-model="form.test3" required></b-form-input>
                                </b-form-group>
                            </div>

                            <div class="col-md-2" v-if="form.assessment>3">
                                <b-form-group label="Assessment 4:">
                                <b-form-input v-model="form.test4" required></b-form-input>
                                </b-form-group>
                            </div>

                        <div class="col-md-2">
                            <b-form-group label="Exam:">
                            <b-form-input v-model="form.exam" required @input="updateExam()"></b-form-input>
                            </b-form-group>
                        </div>

                        <div class="col-md-2">
                            <b-form-group label="Total:">
                            <b-form-input v-model="form.total" readonly></b-form-input>
                            </b-form-group>
                        </div>

                        </div>
                    
                        <div class="row">
                        <div class="col-md-12">
                            <hr>
                        </div>

                        <div class="col-md-6">
                            <b-form-group label="A Max:">
                            <b-form-input v-model="form.a_max" required readonly></b-form-input>
                            </b-form-group>
                        </div>

                        <div class="col-md-6">
                            <b-form-group label="A Min:">
                            <b-form-input v-model="form.a_min" required @input="updateA()"></b-form-input>
                            </b-form-group>
                        </div>

                        <div class="col-md-6">
                            <b-form-group label="B Max:">
                            <b-form-input v-model="form.b_max" required readonly></b-form-input>
                            </b-form-group>
                        </div>

                        <div class="col-md-6">
                            <b-form-group label="B Min:">
                            <b-form-input v-model="form.b_min" required @input="updateB()"></b-form-input>
                            </b-form-group>
                        </div>

                        <div class="col-md-6">
                            <b-form-group label="C Max:">
                            <b-form-input v-model="form.c_max" required readonly></b-form-input>
                            </b-form-group>
                        </div>

                        <div class="col-md-6">
                            <b-form-group label="C Min:">
                            <b-form-input v-model="form.c_min" required @input="updateC()"></b-form-input>
                            </b-form-group>
                        </div>

                        <div class="col-md-6">
                            <b-form-group label="D Max:">
                            <b-form-input v-model="form.d_max" required readonly></b-form-input>
                            </b-form-group>
                        </div>

                        <div class="col-md-6">
                            <b-form-group label="D Min:">
                            <b-form-input v-model="form.d_min" required @input="updateD()"></b-form-input>
                            </b-form-group>
                        </div>

                        <div class="col-md-6">
                            <b-form-group label="E Max:">
                            <b-form-input v-model="form.e_max" required readonly></b-form-input>
                            </b-form-group>
                        </div>

                        <div class="col-md-6">
                            <b-form-group label="E Min:">
                            <b-form-input v-model="form.e_min" required @input="updateE()"></b-form-input>
                            </b-form-group>
                        </div>

                        <div class="col-md-6">
                            <b-form-group label="F Max:">
                            <b-form-input v-model="form.f_max" required readonly></b-form-input>
                            </b-form-group>
                        </div>

                        <div class="col-md-6">
                            <b-form-group label="F Min:">
                            <b-form-input v-model="form.f_min" required readonly></b-form-input>
                            </b-form-group>
                        </div>      
                        </div>
                    </div>
                    <div class="card-footer">
                        <button type="submit" class="btn btn-danger btn-lg btn-block">Submit</button>
                    </div>
                </b-form>
            </div>
        </b-container>
    </b-overlay>
</template>

<script>
    import axios from 'axios';
    import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
    import { Form, HasError, AlertError, AlertSuccess } from 'vform';

    export default {
        data() {
            return {
                is_busy: false,
                editMode: false,
                form: new Form({
                    id: '',
                    test1: '',
                    test2: '',
                    test3: '',
                    test4: '',
                    test5: '',
                    exam: '',
                    f_min: '',
                    f_max: '',
                    assessment: '',
                    current_term: '',
                    total: '',
                    cut_off: '',
                    promotion: '',
                }),
                    
                terms: [{ text: 'Select One', value: null }, { text: 'First Term', value: 1 }, { text: 'Second Term', value: 2 }, { text: 'Third Term', value: 3 }],

                assessments: [{ text: '1 Assessment', value: 1 }, { text: '2 Assessments', value: 2 }, { text: '3 Assessments', value: 3 }, { text: '4 Assessments', value: 4 }],
                school: '',
                standard: '',
                is_busy: false,
            }
        },

        created() {
            this.roleHelper(['1', '5']);
            this.load();
        },

        methods: {
            load() {
                if(this.is_busy) return;
                this.is_busy = true;
                axios.get('/setting/academic-settings')
                .then(res => {
                    this.form = res.data.data;
                    if(!this.form.f_min){
                        this.form.f_min = 0;
                    }
                    if(!this.form.a_max){
                        this.form.a_max = 100;
                    }
                    this.form.total = 100;
                })
                .catch(err => {
                    console.log(err);
                })
                .finally(() => {
                    this.is_busy = false;
                });
            },

            updateUser(){

                if(this.form.total!=100){
                    Swal.fire(
                        'Failed!',
                        'The total assessments plus exam must be equal to 100%!!!',
                        'error'
                    )
                }

                else{
                    if(this.is_busy) return;
                    this.is_busy = true;

                    axios.put('/setting/academic-settings/'+this.form.id, this.form)
                    .then(()=>{
                        Swal.fire(
                            'Updated!',
                            'Settings has been updated.',
                            'success'
                        )
                    })
                    .catch(() => {
                        Swal.fire(
                            'error!',
                            'Settings not updated.',
                            'error'
                        )
                    })
                    .finally(() => {
                        this.is_busy = false;
                        this.load();
                    }) 
                }      
            },

            updateExam(){
                var A = '';
                if(this.form.assessment==1){
                A = Number(this.form.test1);
                }

                if(this.form.assessment==2){
                A = Number(this.form.test1) + Number(this.form.test2);
                }

                if(this.form.assessment==3){
                A = Number(this.form.test1) + Number(this.form.test2) + Number(this.form.test3);
                }

                if(this.form.assessment==4){
                A = Number(this.form.test1) + Number(this.form.test2) + Number(this.form.test3) + Number(this.form.test4);
                }

                this.form.total = Number(this.form.exam) + A;
            },

            updateA(){
                this.form.b_max = Number(this.form.a_min - 1);
            },

            updateB(){
                this.form.c_max = Number(this.form.b_min - 1);
            },

            updateC(){
                this.form.d_max = Number(this.form.c_min - 1);
            },

            updateD(){
                this.form.e_max = Number(this.form.d_min - 1);
            },

            updateE(){
                this.form.f_max = Number(this.form.e_min - 1);
            },
        },
  }
</script>